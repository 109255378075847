<template>
  <div class="productDetails mt-3">
    <!-- <Breadcrumb title="My Cart"/> -->
    <div class="cart">
      <div v-if="cartTotal < 1" class="row">
        <div class="col-md-12">
          <img class="img-responsive empty-image" src="/img/empty_cart.svg" alt="">
          <h5 class="text-center mt-4">Your cart is empty, <router-link style="color:#e54c2a" :to="{name: 'products-list'}">continue shopping?  </router-link></h5>
        </div>
      </div>

    <!--  This is design for 1000 or more width screen    -->
      <div style="display:none" class="container m-screen">
        <div v-if="cartTotal > 0" class="row">
          <div  class="col-md-12">
            <p class="text-center">Total(Tk) : <strong>{{total}}</strong> <br>
            <small class="position">(Including 60 tk delivery charge & 5% VAT)</small></p>
          </div>


          <div v-for="(product,index)  in allProduct" :key="index" class="col-md-12 text-center">
            <ul>
              <li class="mt-4">
                <img :src="product.product.images[0].path" height="60px" width="70px">
              </li>


              <li>
                <strong>Name: </strong>{{product.product.name}}
              </li>
              <li>

                <div class="d-flex justify-center">
                  <strong style="margin-top: 7px;">Quantity: </strong>
                    <v-btn @click="decreaseCount(index)" icon color="green">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>

                    <p class="product-count">{{ product.count }}</p>

                    <v-btn @click="increaseCount(index)" icon color="green">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </div>
              </li>
              <li>
                <strong v-if="product.product.is_discount_percentage == 1">Total: {{(product.product.price - (product.product.price * product.product.discount) / 100 ) * product.count}}</strong>
                <strong v-if="product.product.is_discount_percentage == 0">Total: {{(product.product.price - product.product.discount) * product.count}}</strong>

                <!-- <strong>Total: </strong>{{product.product.price * product.count}} -->
              </li>
              <li>
                <a class="btn btn-block btn-outline-warning mt-2" style="font-size:13px" @click="removeProductItem(index)">
                  Remove
                </a>
              </li>
            </ul>
          </div>

          <div class="w-100 coupon-section-wrapper">
            <div id="coupon-message-wrapper" class="w-100">
              <p class="text-info w-100 text-center">{{ coupon_verification_message }}</p>
            </div>
            <div class="col-md-12 text-center">
              <div class="d-block text-center">
                <p class="font-weight-bold have_any_coupon mt-2 mr-2" @click="show = !show">Have Any Coupon?</p>
                <transition name="slide-fade" v-if="show">
                  <div>
                    <input type="text" class="font-weight-bold border-1 w-100 text-center" style="border: 1px solid #80808033; border-radius: 4px;" v-model="coupon"/>
                    <button class="border_coupon_button btn-success m-2 p-2" style="border-radius: 4px" @click="verifyCoupon" >Verify</button>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div class="col-md-12 text-center">
            <p class="btn-top-text"><small>Including 60 tk delivery charge & 5% VAT</small></p>
          <button v-if="showConfirmOrder" class="btn default-style" @click="checkValidity()">Confirm Order ( ৳ {{ total }} )</button>

          </div>
        </div>
      </div>

      <!--  This is design for 1000 or more less screen  -->
      <div v-if="cartTotal > 0"  class="container d-screen">
        <div class="product-info">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div class="cart-table table-responsive">
                <table  class="table table-striped table-bordered" width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th class="product-remove">Action</th>
                      <th class="product-thumbnail">Image</th>
                      <th class="product-name">Product</th>
                      <th class="product-price">Price (Tk)</th>
                      <th class="product-quantity">Quantity</th>
                      <th class="product-subtotal"> Total (Tk)
                      </th>
                    </tr>
                  </thead>
                  <tr v-for="(product,index)  in allProduct" :key="index">
                    <td style="width:10px">
                      <a class="action" style="font-size:13px" @click="removeProductItem(index)">
                        <i class="fa fa-plus"></i>
                      </a>
                    </td>
                    <td>
                      <img :src="product.product.images[0].path" height="60px" width="70px">
                    </td>
                    <td>
                      <router-link
                        class="action"
                        :to="{name:'product.show',params:{id:product.product.products_id}}"
                      >{{product.product.name}}</router-link>
                    </td>
                    <!-- <td>{{(product.product.price - product.product.discount).toFixed(2)}}</td> -->
                    <!-- <td v-if="product.product.is_discount_percentage == 1">{{(product.product.price - (product.product.price * product.product.discount) / 100 ).toFixed(2)}}</td>
                    <td v-else>{{(product.product.price - product.product.discount).toFixed(2)}}</td> -->

                    <td v-if="product.product.discount > 0">
                      <template v-if="product.product.is_discount_percentage == 1">
                        {{(product.product.price - (product.product.price * product.product.discount) / 100 ).toFixed(2)}}
                      </template>
                      <template v-else>
                        {{(product.product.price - product.product.discount).toFixed(2)}}
                      </template>         
                      <br>
                      <span> <del><span class="text " style="color: #C0272D;">৳</span>{{ product.product.price }}</del></span> 
                      <span style="color:#e54c2a"> {{  parseInt(product.product.discount)}}<span>{{ (product.product.is_discount_percentage == 1 ? '%' : 'tk' ) }}</span></span>
                    </td>
                    <td v-else>{{product.product.price}}</td>
                    <td style="width:17%">
                      <!-- <input
                        style="background-color: white;text-align: center;"
                        id="productQuantity"
                        type="number"
                        v-model="product.count"
                        v-on:input="productItemIncrease(index,product.count)"
                      > -->
                      <div class="d-flex justify-space-between">
                          <v-btn @click="decreaseCount(index)" icon color="green">
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>

                          <p class="product-count">{{ product.count }}</p>

                          <v-btn @click="increaseCount(index)" icon color="green">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                      </div>
                    </td>
                    <td v-if="product.product.is_discount_percentage == 1">{{((product.product.price - (product.product.price * product.product.discount) / 100 ) * product.count).toFixed(2)}}</td>
                    <td v-if="product.product.is_discount_percentage == 0">{{((product.product.price - product.product.discount) * product.count).toFixed(2)}}</td>

                  </tr>
                  <tr>
                    <td colspan="5" class="text-right">
                      Total Price = <br>
                      <span v-if="couponDiscount > 0">Coupon Discount = <br></span>
                      5% VAT = <br>
                      Delivery Charge = <br>
                      <strong>Grand Total =</strong>
                    </td>
                    <td>
                        {{ (actualTotalPrice).toFixed(2) }} <br>
                        <span v-if="couponDiscount > 0">{{ parseInt(couponDiscount).toFixed(2) }} <br></span>
                        {{ vat() }}   <br>
                        {{ deliveryCharge }}<br>
                       <strong>  {{ total }}</strong>
                    </td>
                  </tr>
                </table>
                <p v-if="outOfStock">Sorry, out of stock, Please remove,
                  <span style="color:red" v-for="(item, index) in stockOutProduct" :key="index">{{index+1}}. {{ item.name }} </span> then comfirm agin. </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div>
              <p class="text-info float-right">{{ coupon_verification_message }}</p>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div class="float-right d-flex">
                <p class="font-weight-bold have_any_coupon mt-2 mr-2" @click="show = !show">Have Any Coupon?</p>
                <transition name="slide-fade" v-if="show">
                  <div>
                    <input type="text" class="font-weight-bold border-1" style="width: 200px; border: 1px solid #80808033; border-radius: 4px;" v-model="coupon"/>
                    <button type="text" class="border_coupon_button btn-success m-2 p-2" style="border-radius: 4px" @click="verifyCoupon" >Verify</button>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <button :disabled="disabled" v-if="showConfirmOrder" class="btn default-style pull-right" @click="checkValidity()">Confirm Order</button>
            </div>
          </div>
          <div class="row" v-if="order_history"></div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
// import Breadcrumb from "@/components/common/BreadcrumbComponent";
export default {
  name: 'CartPage',
  props: ["title"],
  // components: { Breadcrumb },
  data() {
    return {
      disabled        : false,
      outOfStock      : false,
      products        : [],
      stockOutProduct : [],
      allProduct      : this.$store.state.cart,
      img             : "https://s3.ap-south-1.amazonaws.com/german-butcher/",
      quantity        : 0,
      order_history   : "",
      deliveryCharge  : 60,
      productPriceWithDiscount: 0,

      show            : false,
      coupon_verified : false,
      coupon          : '',
      couponDiscount  : 0,
      coupon_verification_message: '',
    };
  },

  computed: {
    total: {
      get: function () {
        let total = (parseFloat(this.cartTotal) + parseFloat(this.vat()) + parseFloat(this.deliveryCharge) - parseFloat(this.couponDiscount)).toFixed(2);
        return (total < 0) ? 0 : total;
      },
      // setter
      set: function (newValue) {
        this.couponDiscount = newValue;
      }
    },
    cartTotal: {
      get: function (){
        let sum = 0;
        for (let i = 0; i < this.$store.state.cart.length; i++) {
          let currentItem = this.$store.state.cart[i];
          if(currentItem.product.is_discount_percentage == 0){
                sum += (currentItem.product.price - currentItem.product.discount)* currentItem.count;
            }
            else{
                sum += (currentItem.product.price - (currentItem.product.price * currentItem.product.discount) / 100)* currentItem.count;
            }
          // sum += (currentItem.product.price - currentItem.product.discount) * currentItem.count;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.productPriceWithDiscount = sum;
        return this.productPriceWithDiscount;
      },

      set: function (newValue){
        this.productPriceWithDiscount = newValue;
      }
    },
    actualTotalPrice() {
      let sum = 0;
      for (let i = 0; i < this.$store.state.cart.length; i++) {
        let currentItem = this.$store.state.cart[i];
        if(currentItem.product.is_discount_percentage == 0){
                sum += (currentItem.product.price - currentItem.product.discount)* currentItem.count;
            }
            else{
                sum += (currentItem.product.price - (currentItem.product.price * currentItem.product.discount) / 100)* currentItem.count;
            }
        // sum += currentItem.product.price * currentItem.count;
      }
      return sum;
    },
    grandTotalPrice() {
      let sum = 0;
      for (let i = 0; i < this.$store.state.cart.length; i++) {
        let currentItem = this.$store.state.cart[i];
        sum += currentItem.product.price * currentItem.count;
      }
      return sum;
    },
    totalDiscountPrice() {
      var sum = 0;
      for (var i = 0; i < this.$store.state.cart.length; i++) {
        var currentItem = this.$store.state.cart[i];
        if(currentItem.product.is_discount_percentage == 0){
                sum += currentItem.product.discount * currentItem.count;
            }
            else{
                sum += (currentItem.product.price  *  currentItem.product.discount) / 100 * currentItem.count;
            }
        // sum += currentItem.product.discount * currentItem.count;
      }
      return sum;
    },
    showConfirmOrder: function () {
      if(this.$store.state.cart.length > 0){
        return true;
      }else{
        return false;
      }
    }
  },
  methods: {

    checkValidity() {
      let loginInfo = localStorage.getItem("userToken");
      let user_id = localStorage.getItem("fk_uses_id");
      if (loginInfo === null) {
        this.$router.push({ name: "signIn" });
      }
      else if( user_id === null)
      {
         this.$router.push({ name: "signIn" });
      }
      else {
        let cart = this.allProduct;
        this.products = []
        cart.map(i =>{
          this.products.push(i.product.products_id)
                // return {
                //   fk_products_id: i.product.products_id,
                // }
        })
        this.disabled = true
        this.outOfStock = false
        this.stockOutProduct = []
        this.axios.post(`/checkStock?token=${loginInfo}`, { products_id : this.products})
        .then(response => {
          // this.$store.commit('addTCart',0);
          if(response.data.success == true){

            this.$router.push({name: 'cart.place.order', query: { 'couponCode': this.coupon }});

            this.emptyCouponInformation();

            this.disabled = false
          }else{
            this.outOfStock = true
            this.disabled = false
            this.stockOutProduct = response.data.message
          }

        });
      }
    },

    emptyCouponInformation(){
      this.show             = false;
      this.coupon_verified  = false;
      this.coupon           = '';
      this.couponDiscount   = 0;
      this.coupon_verification_message = '';
    },

    OrderHistory() {
      // let order_id = localStorage.getItem("fk_uses_id");
      let order_id=2
      if(order_id === null){
        return false;
      }
      else{
      this.axios.get("/order-detail?order_id=" + order_id).then(response => {
        this.$notify({
          group : 'msg',
          type  : 'success',
          title : 'Success',
          text  : 'Your Order History!',
        });
        this.order_history = response.data.orderDetail;
      });
      }
    },

    removeProductItem(index) {
      this.$store.commit("cartItemRemove", index);
      localStorage.removeItem("cart");
      localStorage.setItem(
        "cart",
        JSON.stringify(this.$store.getters.cart_Info)
      );
    },

    increaseCount(index){
      this.allProduct[index].count++
    },

    decreaseCount(index){
      if(this.allProduct[index].count <=1){
        return ;
      }else{
        this.allProduct[index].count--
      }

    },

    /**
     * Calculating Vat Based on Current Ordered Product Items and is_VATable Proportional to 5%
     * @return {string}
     */
    vat(){
      let totalVat = 0;
      for (let i = 0; i < this.$store.state.cart.length; i++) {
        let currentItem = this.$store.state.cart[i];
        let vat = (currentItem.product.is_VATable != 0)
            ? (currentItem.product.is_discount_percentage == 0)
            ? ( ( currentItem.product.price * currentItem.count ) - currentItem.product.discount ) * .05 
            : ( ( currentItem.product.price * currentItem.count ) - ((currentItem.product.price  *  currentItem.product.discount) / 100 )) * .05 
            : 0;
        totalVat = totalVat + vat;
        }
      return totalVat.toFixed(2)
    },

    /**
     * @param index
     * @param productCount
     */
    productItemIncrease(index, productCount) {
      if (productCount <= 0) {
        this.allProduct[index].count = 1;
      }
    },

    /**
     * Verifying Coupon By Sending Request to Backend Server
     */
    verifyCoupon(){
      this.checkIfLoggedIn();
      let data = {
        'coupon_code': this.coupon,
        'product_price_total': this.actualTotalPrice.toFixed(2),
        'user_id': localStorage.getItem("fk_uses_id")
      };

      this.axios.post('coupon/apply', data).then(response => {
        this.coupon_verification_message = response.data.message
        if (response.data.success === true){
          this.updateCurrentOrderProperties(response.data.data);
        }

      });
    },

    // Checking If The use is logged in or not.
    checkIfLoggedIn(){
      let loginInfo = localStorage.getItem("userToken");
      let user_id = localStorage.getItem("fk_uses_id");
      if (loginInfo === null) {
        this.$router.push({ name: "signIn" });
      }
      else if( user_id === null)
      {
        this.$router.push({ name: "signIn" });
      }
    },

    /**
     * Changing Order Properties Based On Applied Coupon
     * @param responseData
     */
    updateCurrentOrderProperties(responseData){
      this.couponDiscount = (responseData.discount_is_percentage == 1)
          ? ( responseData.discount_amount / 100 ) * this.actualTotalPrice
          : responseData.discount_amount;
      if (responseData.is_delivery_charge_free == 1){
        this.deliveryCharge = 0;
      }
    }

  }
};
</script>

<style scoped>
.breadcrumb-area:before {
    background-color: white !important;
    width: 0%;
    height: 0%;
}
.have_any_coupon {
  cursor: pointer;
}
.have_any_coupon:hover {
  color: #e54c2a;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.cart{
        min-height: calc(100vh - 300px);
    }
.btn {
  border-radius: 16px;
  font-size: 14px;
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;

}

.fa-plus {
  transform: rotate(45deg);
}
.action {
  cursor: pointer;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  background-color: black;
  color: white;
}
.cart-product {
  margin-bottom: 122px;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #e9ecef;
}

.product-background {
  /* background-image: url("/img/blog/Singlerecipe-1.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: black; */
}
.product-heading {
  text-align: center;
  color: white;
  margin-top: 45px;
}
.product-heading span {
  padding: 3px;
  text-align: center;
  font-weight: bold;
  color: #fff;
}
.product-info {
  background-color: white;
  margin-top: 45px;
  padding: 50px;
}
.default-style{
    background-color: #e54c2a;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    line-height: 0;
    padding: 22px 25px;
    text-transform: uppercase;
    }
ul li{
  list-style: none;
}
  @media only screen and (max-width: 997px){
      .m-screen {
          display: block !important;
      }
  }
  @media only screen and (max-width: 997px){
      .d-screen {
          display: none !important;
      }
  }
  .empty-image{
    display: block;
    margin: auto;
    max-height: 250px;
    margin-top: 60px;
  }
  .position{
    transform: translateY(-11px);
    display: inline-block;
  }
  .btn-top-text{
    padding: 0;
    margin: -10px 0 0 0;
  }
  .justify-space-between{
    justify-content: space-around;
  }
  .justify-center{
    justify-content: center;
  }
  .product-count{
    font-size: 16px;
    margin-bottom: 0px;
    padding: 0 5px;
    margin-top: 5px;
  }
</style>
